<template>
  <!-- social buttons -->
  <div class="auth-footer-btn d-flex justify-content-center">
    <!-- STARRED PAGES - FIRST 10 -->
    <ul style="margin-bottom: 0 !important; padding-inline-start: 10px;">
      <li style="display: inline;">
        <a
          href="https://play.google.com/store/apps/details?id=com.tusbuddy"
          target="_blank"
        >
          <img
            src="@/assets/images/app-icons/google-play.svg"
            width="22"
            height="22"
            style="margin: 5px;"
          />
        </a>
      </li>

      <li style="display: inline;">
        <a
          href="https://apps.apple.com/tr/app/tusbuddy/id1547209630?l=tr"
          target="_blank"
        >
          <img
            src="@/assets/images/app-icons/appstore.svg"
            width="22"
            height="22"
            style="margin: 5px;"
          />
        </a>
      </li>

      <li style="display: inline;">
        <a href="https://t.me/tus_buddy" target="_blank">
          <img
            src="@/assets/images/app-icons/telegram.svg"
            width="22"
            height="22"
            style="margin: 5px;"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>
